<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-card flat class="card-datatable">
      <v-card-text>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-end px-2" cols="4" md="3" xl="3">
            <v-btn
              color="#38227A"
              dark
              class="text-capitalize px-7"
              @click="toggleAddEditSponsorModal({ show: true, type: 'add' })"
              height="45px"
              style="border-radius: 10px"
            v-if=" $vuetify.breakpoint.smAndUp" >
              <span>Add New Sponsor</span>
            </v-btn>
              <v-menu offset-y  v-if="this.adminAccess==='Super Admin'  && $vuetify.breakpoint.xsOnly" >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class">
                  <v-list-item  @click="toggleAddEditSponsorModal({ show: true, type: 'add' })">
                    <v-list-item-title>Add New Sponsor</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div">
      <v-data-table
        fixed-header
        hide-default-footer
        :headers="sponsorDataTable.headers"
        :items="sponsorDataTable.items"
        :loading="sponsorDataTable.loading"
        :items-per-page="
          sponsorDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="sponsorDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]"
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="props">
          <tr class="text-flex" hieght="6-px" style="font-size: 14px">
            <td class="text-center row-item px-0">
              <v-row class="justify-center">
                <v-col class="py-0" cols="10" md="12" lg="11" xl="8">
                  <div class="d-flex align-center" style="">
                    <img width="50" :src="props.item.sponsor_logo_url" />
                    <div class="ml-2">
                      <div class="text-left" style="font-size: 14px;">
                        {{ props.item.sponsor_name }}
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </td>
            <td class="text-center row-item py-2">
              <div>{{ props.item.contact_person_name }}</div>
              <div>{{ props.item.contact_Person_phone }}</div>
              <div>{{ props.item.contact_person_email }}</div>
            </td>
            <td class="text-center row-item">
              <div class="px-10 pt-5" style="margin-left: 30%;">
                <v-switch
                  class="py-0 my-0"
                  color="#067605"
                  v-model="props.item.status"
                  @change="changeStatus(props.item.status, props.item.id)"
                >
                  <template v-slot:label>
                    <div
                      v-if="props.item.status"
                      style="color: #067605; font-size: 14px"
                    >
                      Active
                    </div>
                    <div v-else style="font-size: 14px">In-active</div>
                  </template>
                </v-switch>
              </div>
            </td>
            <td class="text-center row-item">
              <v-row class="justify-center">
                <v-col class="pa-0 text-center" cols="12" xl="8">
                  <v-chip
                    @click="
                      toggleAddEditSponsorModal({
                        show: true,
                        type: 'edit',
                        id: props.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    @click="
                      toggleDeleteSponsorModal({
                        Delete: true,
                        id: props.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-chip>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="sponsorDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-5 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="
                    justify-center justify-md-start
                    d-flex
                    px-5
                    tableHeader-text
                  "
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPage"
                      hide-details
                      v-model="
                        sponsorDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{
                      sponsorDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      sponsorDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      sponsorDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      sponsorDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="sponsorDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      flat
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="
                justify-center justify-md-start
                d-flex
                px-5
                tableHeader-text
              "
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    sponsorDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  sponsorDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  sponsorDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  sponsorDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  sponsorDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <add-edit-sponsor-modal @reload="getSponsorData()"></add-edit-sponsor-modal>
    <delete-sponsor-modal @reload="getSponsorData()"></delete-sponsor-modal>
  </div>
</template>
<script>
import {
  API_ADMIN_SPONSOR_MANAGEMENT_LIST,
  API_ADMIN_SPONSOR_MANAGEMENT_UPDATE,
} from "@/constants/APIUrls";
import authToken from "@/common/authToken";
import { mapActions, mapGetters } from "vuex";
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  DATA,
  SPONSOR_LIST,
} from "@/constants/APIKeys";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import Axios from "@/api/BaseAxios";
export default {
  name: "SponsorManagement",
  components: {
    AddEditSponsorModal: () => import("./AddEditSponsor/AddEditSponsor.vue"),
    DeleteSponsorModal: () =>
      import("./DeleteSponsorConfirmation/DeleteSponsorConfirmation.vue"),
  },
  data() {
    return {
     adminAccess:localStorage.getItem("adminAccess"),
      sponsorDataTable: {
        headers: [
          {
            sortable: false,
            text: "Sponsor Info",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
            value: "sponsor_info",
          },
          {
            sortable: false,
            text: "Contact Person Info",
            align: "center",
            value: "contact_person_info",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Status",
            align: "center",
            value: "status",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
      sponsorSwitch: true,
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "sponsorDataTable.page_info.page_number": function () {
      this.getSponsorData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "sponsorDataTable.page_info.detail_count": function () {
      this.getSponsorData();
    },
  },
  methods: {
    ...mapActions({
      // teacher Modal
      toggleAddEditSponsorModal: "sponsorManagement/toggleModal",
      toggleDeleteSponsorModal: "sponsorManagement/toggleDeleteModal",
    }),
    getSponsorData() {
      const self = this;
      self.sponsorDataTable.loading = true;
      console.log("sponcers");
      const successHandler = (res) => {
        console.log(
          "ITEMS_PER_PAGE: ",
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE],
          "PAGE_NUMBER: ",
          res[DATA][PAGE_INFO][PAGE_NUMBER],
          "TOTAL_PAGE: ",
          res[DATA][PAGE_INFO][TOTAL_PAGE],
          "STUDENT_LIST: ",
          res[DATA][SPONSOR_LIST],
          res.data
        );
        self.sponsorDataTable.items = res[DATA][SPONSOR_LIST];
        self.sponsorDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.sponsorDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.sponsorDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];

        self.sponsorDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.sponsorDataTable.loading = false;
      };
      let dataTableParams = {};
      if (this.sponsorDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.sponsorDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.sponsorDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.sponsorDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      if (this.layout === "CampaignDashboardLayout") {
        dataTableParams["campaign_id"] =
          this.selectedCampaignData.campaignData.id;
        if (this.userTeam === "admin") {
          if (this.$route.matched[0].path.substring(1) === "district") {
            dataTableParams["district_id"] =
              this.selectedCampaignData.organizationData.id;
          } else {
            dataTableParams["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        } else if (this.userTeam === "district") {
          if (this.$route.matched[0].path.substring(1) === "school") {
            dataTableParams["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        }
      }
      console.log(dataTableParams);
      Axios.request_GET(
        API_ADMIN_SPONSOR_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    changeStatus(status, id) {
      console.log(status, id);
      const successHandler = (res) => {
        console.log(res.data, "status changed");
      };
      const failureHandler = (res) => {
        console.log(res.data);
      };
      let formData = new FormData();
      formData.append("sponsor_id", id);
      formData.append("status", status);
      Axios.request_PATCH(
        API_ADMIN_SPONSOR_MANAGEMENT_UPDATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  created(){
    this.$root.$refs.sponsorManagement = this;
  },
  mounted() {
  this.sponsorDataTable.loading = false;
    this.getSponsorData();
  },
};
</script>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 70px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 80px;
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;

  margin-left: 12px;
}
.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
.text-capitalize.v-btn >>> span {
  letter-spacing: 0px;
  font-family: Lato;
  font-weight: 600;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    min-width: 1200px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
